// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.game {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  aspect-ratio: 3/4;
  min-height: 100vmin;
  min-width: 100vmin;
  max-width: 100vw;
  max-height: 100vh;
  background-color: black;
  z-index: 99;
  font-family: "Roboto Mono", monospace;
  animation: fadeIn 6s 1;
}

.game .fade-in {
  animation: fadeIn 2s 1;
}

@keyframes fadeOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.sprite {
  position: absolute;
  width: 40%;
  transform: translate(-50%, -100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.reaction {
  position: absolute;
  right: 28%;
  bottom: 120%;
  z-index: 5;
}

.reaction-hidden {
  position: absolute;
  right: 28%;
  bottom: 120%;
  font-size: 5em;
  opacity: 0;
  transform: translate(50px, 20px);
  animation-duration: 3s;
  animation-name: slidein, fadeOut;
  z-index: 5;
}

@keyframes slidein {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-150%);
  }
}
.fade-in {
  transition: 2s linear;
}`, "",{"version":3,"sources":["webpack://./src/styles/Game.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,uBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,WAAA;EACA,qCAAA;EACA,sBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE;IAEE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF;AAGA;EACE,kBAAA;EACA,UAAA;EACA,iCAAA;EACA,wBAAA;EACA,4BAAA;EACA,2BAAA;AADF;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,UAAA;AADF;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,cAAA;EACA,UAAA;EACA,gCAAA;EACA,sBAAA;EACA,gCAAA;EACA,UAAA;AADF;;AAIA;EACE;IACE,yBAAA;EADF;EAIA;IACE,4BAAA;EAFF;AACF;AAKA;EACE,qBAAA;AAHF","sourcesContent":[".game {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  justify-content: center;\n  aspect-ratio: 3/4;\n  min-height: 100vmin;\n  min-width: 100vmin;\n  max-width: 100vw;\n  max-height: 100vh;\n  background-color: black;\n  z-index: 99;\n  font-family: \"Roboto Mono\", monospace;\n  animation: fadeIn 6s 1;\n}\n\n.game .fade-in {\n  animation: fadeIn 2s 1;\n}\n\n@keyframes fadeOut {\n  0%,\n  100% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 1;\n  }\n}\n\n.sprite {\n  position: absolute;\n  width: 40%;\n  transform: translate(-50%, -100%);\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n.reaction {\n  position: absolute;\n  right: 28%;\n  bottom: 120%;\n  z-index: 5;\n}\n\n.reaction-hidden {\n  position: absolute;\n  right: 28%;\n  bottom: 120%;\n  font-size: 5em;\n  opacity: 0;\n  transform: translate(50px, 20px);\n  animation-duration: 3s;\n  animation-name: slidein, fadeOut;\n  z-index: 5;\n}\n\n@keyframes slidein {\n  from {\n    transform: translateY(0%);\n  }\n\n  to {\n    transform: translateY(-150%);\n  }\n}\n\n.fade-in {\n  transition: 2s linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
