// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/field.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleScreen {
  display: flex;
  aspect-ratio: 3/4;
  width: 100%;
  max-height: 95%;
  min-height: 100vmin;
  min-width: 100vmin;
  max-width: 100vw;
  max-height: 100vh;
  color: white;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  align-items: center;
  justify-content: center;
  align-content: center;
  background-size: cover;
}

h2 {
  color: white;
  background-color: rgba(0, 0, 0, 0);
  font-size: 7rem;
  font-family: "Lobster", sans-serif;
  animation: fadeInAndOut 7s;
  opacity: 0;
}

@keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.intro {
  border: black solid 3px;
  background-color: rgba(255, 255, 255, 0.97);
  color: black;
  font-family: "Courier New", Courier, monospace;
  border-radius: 25px;
  padding: 1rem;
  animation: fadeIn 6s;
}`, "",{"version":3,"sources":["webpack://./src/styles/TitleScreen.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,yDAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;EACA,kCAAA;EACA,eAAA;EACA,kCAAA;EACA,0BAAA;EACA,UAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AAEA;EACE;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF;AAGA;EACE,uBAAA;EACA,2CAAA;EACA,YAAA;EACA,8CAAA;EACA,mBAAA;EACA,aAAA;EACA,oBAAA;AADF","sourcesContent":[".TitleScreen {\n  display: flex;\n  aspect-ratio: 3/4;\n  width: 100%;\n  max-height: 95%;\n  min-height: 100vmin;\n  min-width: 100vmin;\n  max-width: 100vw;\n  max-height: 100vh;\n  color: white;\n  background-image: url(\"../assets/images/field.jpg\");\n  align-items: center;\n  justify-content: center;\n  align-content: center;\n  background-size: cover;\n}\n\nh2 {\n  color: white;\n  background-color: rgba(0, 0, 0, 0);\n  font-size: 7rem;\n  font-family: 'Lobster', sans-serif;\n  animation: fadeInAndOut 7s;\n  opacity: 0;\n}\n\n@keyframes fadeInAndOut {\n  0% {\n    opacity: 0;\n  }\n  40% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.intro {\n  border: black solid 3px;\n  background-color: rgba(255, 255, 255, 0.97);\n  color: black;\n  font-family: 'Courier New', Courier, monospace;\n  border-radius: 25px;\n  padding: 1rem;\n  animation: fadeIn 6s;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
