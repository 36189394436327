// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-stats {
  max-height: calc(-moz-fit-content + 1px);
  max-height: calc(fit-content + 1px);
  position: absolute;
  top: 1%;
  left: 1%;
  background-color: whitesmoke;
  color: black;
  width: 30%;
  border-radius: 25px;
  border: black solid 3px;
  box-shadow: 2px 2px 5px 2px black;
  padding: 0.3rem;
  font-size: 1rem;
}

.energy-bar-container {
  display: flex;
  flex-wrap: wrap;
  object-fit: cover;
  border-radius: 10px;
  flex-direction: column;
  border: darkgray solid 1px;
  background-color: lightgray;
  height: 1em;
  z-index: 900;
  align-content: flex-start;
  padding: 0.25em;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}

.energy-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
  overflow: hidden;
  transition: width 0.6s;
}

.energy {
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
  transition: width 0.3s ease-in-out;
}

.energy-green {
  display: flex;
  align-self: flex-start;
  background-color: green;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  padding: none;
  z-index: 900;
}

.energy-text {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/UserStats.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EAAA,mCAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,4BAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;EACA,uBAAA;EACA,iCAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,sBAAA;EACA,0BAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,kCAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,qBAAA;AACF","sourcesContent":[".user-stats {\n  max-height: calc(fit-content + 1px);\n  position: absolute;\n  top: 1%;\n  left: 1%;\n  background-color: whitesmoke;\n  color: black;\n  width: 30%;\n  border-radius: 25px;\n  border: black solid 3px;\n  box-shadow: 2px 2px 5px 2px black;\n  padding: 0.3rem;\n  font-size: 1rem;\n}\n\n.energy-bar-container {\n  display: flex;\n  flex-wrap: wrap;\n  object-fit: cover;\n  border-radius: 10px;\n  flex-direction: column;\n  border: darkgray solid 1px;\n  background-color: lightgray;\n  height: 1em;\n  z-index: 900;\n  align-content: flex-start;\n  padding: 0.25em;\n  margin-top: .4em;\n  margin-bottom: .4em;\n}\n\n.energy-bar {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  height: 100%;\n  background-color: #4caf50;\n  border-radius: 10px;\n  overflow: hidden;\n  transition: width 0.6s;\n}\n\n.energy {\n  height: 100%;\n  background-color: #4caf50;\n  border-radius: 10px;\n  transition: width 0.3s ease-in-out;\n}\n\n.energy-green {\n  display: flex;\n  align-self: flex-start;\n  background-color: green;\n  height: 100%;\n  width: 100%;\n  vertical-align: middle;\n  padding: none;\n  z-index: 900;\n}\n\n.energy-text {\n  margin-top: .3rem;\n  margin-bottom: .3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
