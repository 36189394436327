// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pet-stats {
  display: flex;
  flex-direction: column;
  width: 30%;
  position: absolute;
  margin-top: 2em;
  top: 0%;
  right: 0%;
  margin-right: 2em;
}

.mood-bar-container {
  display: flex;
  margin: 0.3em;
  flex-wrap: wrap;
  object-fit: cover;
  border-radius: 10px;
  flex-direction: column;
  border: darkgray solid 1px;
  background-color: lightgray;
  height: 1em;
  z-index: 900;
  align-content: flex-start;
  padding: 0.25em;
}

.mood-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  max-width: 100%;
  background-color: #be4a4a;
  border-radius: 10px;
  overflow: hidden;
  transition: width 0.6s;
}`, "",{"version":3,"sources":["webpack://./src/styles/MoodBar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,kBAAA;EACA,eAAA;EACA,OAAA;EACA,SAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,sBAAA;EACA,0BAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;AACF","sourcesContent":[".pet-stats {\n  display: flex;\n  flex-direction: column;\n  width: 30%;\n  position: absolute;\n  margin-top: 2em;\n  top: 0%;\n  right: 0%;\n  margin-right: 2em;\n}\n\n.mood-bar-container {\n  display: flex;\n  margin: 0.3em;\n  flex-wrap: wrap;\n  object-fit: cover;\n  border-radius: 10px;\n  flex-direction: column;\n  border: darkgray solid 1px;\n  background-color: lightgray;\n  height: 1em;\n  z-index: 900;\n  align-content: flex-start;\n  padding: 0.25em;\n}\n\n.mood-bar {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  height: 100%;\n  max-width: 100%;\n  background-color: #be4a4a;\n  border-radius: 10px;\n  overflow: hidden;\n  transition: width 0.6s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
