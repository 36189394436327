// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  background-color: black;
}

#root {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  height: 100%;
  font-size: calc(1em + 0.6vmin);
  overflow: visible;
}

.App {
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
  max-width: 768px;
  max-height: 1024px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.span {
  z-index: 999;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

button {
  color: black;
  font-family: "Roboto Mono", monospace;
}

button:hover {
  box-shadow: 2px 2px 2px 2px #888888;
  cursor: pointer;
}

@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,qBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,qCAAA;AACF;;AAEA;EACE,mCAAA;EACA,eAAA;AACF;;AAEA;EACE;IACE,sBAAA;EACF;AACF","sourcesContent":["html {\n  background-color: black;\n}\n\n#root {\n  position: relative;\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n  min-width: 100vw;\n  width: 100%;\n  height: 100%;\n  font-size: calc(1em + .6vmin);\n  overflow: visible;\n}\n\n.App {\n  position: absolute;\n  text-align: center;\n  height: 100%;\n  width: 100%;\n  max-width: 768px;\n  max-height: 1024px;\n  background-color: black;\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  flex-wrap: wrap;\n  align-items: center;\n}\n\n.span {\n  z-index: 999;\n  height: 100%;\n  width: 100%;\n  cursor: pointer;\n}\n\nbutton {\n  color: black;\n  font-family: 'Roboto Mono', monospace;\n}\n\nbutton:hover {\n  box-shadow: 2px 2px 2px 2px #888888;\n  cursor: pointer;\n}\n\n@media (max-width: 768px) {\n  .App {\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
